import request from "../helpers/request";
import config from "../settings";
import { formatObject } from "./utility";

const defaultOptions = { credentials: "same-origin", headers: {} };
const methodsWithPayload = ["POST", "PUT", "PATCH", "DELETE"];
const API_URL = `${config.baseUrl}/api`;
const IMAGE_UPLOAD = `${config.imageUpload}/api`

/* eslint-disable no-underscore-dangle */
function _apiCall(path, options = {}) {
  const defaultedOptions = Object.assign({}, defaultOptions, options);

  if (
    methodsWithPayload.indexOf(defaultedOptions.method) > -1 &&
    defaultedOptions.body
  ) {
    defaultedOptions.headers["Content-Type"] = "application/json";
    defaultedOptions.body =
      typeof defaultedOptions.body === "string"
        ? defaultedOptions.body
        : JSON.stringify(defaultedOptions.body);
  }
  if (defaultedOptions.auth) {
    defaultedOptions.headers.Authorization = `Bearer ${getUserAuthToken()}`;
  }

  let defaultedPath = typeof path === "string" ? path : path.join("/");

  if (defaultedOptions.params) {
    const esc = encodeURIComponent;
    const query = Object.keys(defaultedOptions.params)
      .map((k) => `${esc(k)}=${esc(defaultedOptions.params[k])}`)
      .join("&");
    defaultedPath = `${defaultedPath}?${query}`;
  }

  return request(`${API_URL}/${defaultedPath}`, defaultedOptions).then(
    (result) => result
  );
}

export function getUserAuthToken() {
  return localStorage.getItem("id_token");
}

function makeRequest(method, url, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    // xhr.setRequestHeader('Cache-Control', 'public,max-age=3600');
    xhr.setRequestHeader("Authorization", `Bearer ${getUserAuthToken()}`);
    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        // let data = url;
        resolve(JSON.parse(xhr.response));
      } else {
        let message = "";
        try {
          const parse = JSON.parse(xhr.response);
          if (parse.error && parse.error.message) message = parse.error.message;
          else if (parse.data && parse.data.error && parse.data.error.message);
          message = parse.data.error.message;
        } catch (e) {}
        reject({
          status: this.status,
          error: { message },
        });
      }
    };

    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };

    xhr.send(file);
  });
}

let callId = 0;
export const call =
  process.env.NODE_ENV === "production"
    ? (path, options = {}, json = true, ...rest) => {
        callId += 1;
        const thisCallId = callId;
        return _apiCall(path, options, json, ...rest).then(
          (result) => {
            return result;
          },
          (error) => {
            if (error && error.message === "Invalid token") {
              window.location.replace("/signin");
            }
            throw error;
          }
        );
      }
    : (path, options = {}, json = true, ...rest) => {
        callId += 1;
        const thisCallId = callId;
        /* eslint-disable no-console */

        return _apiCall(path, options, json, ...rest).then(
          (result) => {
            return result;
          },
          (error) => {
            if (error && error.message === "Invalid token") {
              window.location.replace("/signin");
            }

            throw error;
          }
        );
        /* eslint-enable no-console */
      };

export default {
  auth: {
    login(payload) {
      return call(["auth", "login"], {
        method: "POST",
        body: payload,
      });
    },
    logout() {
      return call(["auth", "signout"], {
        method: "GET",
        auth: true,
      });
    },
  },
  area: {
    tableList(payload = {}) {
      return call(["admin", "ticketArea", "v2"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    add(payload) {
      return call(["admin", "ticketArea", "v2"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    delete(ticketAreaId) {
      return call(["admin", "ticketArea", "v2", ticketAreaId], {
        method: "DELETE",
        auth: true,
      });
    },
    update(payload) {
      return call(["admin", "ticketArea", "v2"], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
  },
  type: {
    tableList(payload = {}) {
      return call(["admin", "ticketType", "v2"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    add(payload) {
      return call(["admin", "ticketType", "v2"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    delete(ticketAreaId) {
      return call(["admin", "ticketType", "v2", ticketAreaId], {
        method: "DELETE",
        auth: true,
      });
    },
    update(payload) {
      return call(["admin", "ticketType", "v2"], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
  },
  common: {
    insight() {
      return call(["admin", "insight"], {
        method: "GET",
        auth: true,
      });
    },
    listOrganization() {
      return call(["admin", "organization"], {
        method: "GET",
        auth: true,
      });
    },
    classroomStudentList(payload = {}) {
      return call(["user"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    fetchHashTags(qs) {
      return call(["admin", "hashtag"], {
        method: "GET",
        auth: true,
        params: {
          q: qs,
        },
      });
    },
    fetchFields(payload) {
      return call(["admin", "settings"], {
        method: "GET",
        auth: true,
        ...(payload ? { params: payload } : null),
      });
    },
    updateSettings(payload) {
      return call(["admin", "settings"], {
        method: "PATCH",
        auth: true,
        body: payload,
      });
    },
    updateAdminSettings(payload) {
      return call(["admin", "settings", "superAdmin"], {
        method: "PATCH",
        auth: true,
        body: payload,
      });
    },
    fetchAdminSettings() {
      return call(["admin", "settings", "superAdmin"], {
        method: "GET",
        auth: true,
      });
    },
    schools() {
      return call(["admin", "school"], {
        method: "GET",
        auth: true,
      });
    },
    classrooms(payload) {
      return call(["admin", "classroom"], {
        method: "GET",
        auth: true,
        ...(payload
          ? {
              params: payload,
            }
          : {}),
      });
    },
    organizationSchools(organization_id) {
      return call(["admin", "school"], {
        method: "GET",
        auth: true,
        params: organization_id
          ? {
              organization: organization_id,
            }
          : {},
      });
    },
    organizationCourses(organization_id) {
      return call(["admin", "course"], {
        method: "GET",
        auth: true,
        params: organization_id
          ? {
              organization: organization_id,
            }
          : {},
      });
    },
    teachers(params) {
      return call(["admin", "user"], {
        method: "GET",
        auth: true,
        params: formatObject(params),
      });
    },
    students(id) {
      return call(["user", "students"], {
        method: "GET",
        auth: true,
        params: {
          school: id,
        },
      });
    },
    subjects() {
      return call(["admin", "subject"], {
        method: "GET",
        auth: true,
      });
    },
    grades() {
      return call(["admin", "grade"], {
        method: "GET",
        auth: true,
      });
    },
    roles() {
      return call(["admin", "role"], {
        method: "GET",
        auth: true,
      });
    },
    classrooms(payload) {
      return call(["admin", "classroom"], {
        method: "GET",
        auth: true,
        ...(payload
          ? {
              params: payload,
            }
          : {}),
      });
    },
    import(payload) {
      return makeRequest("POST", `${API_URL}/admin/custom/import/`, payload);
    },
    encrypt(payload) {
      return makeRequest("POST", `${API_URL}/admin/custom/encrypt/`, payload);
    },
    saveDoe(payload) {
      return makeRequest("POST", `${API_URL}/admin/custom/doe/`, payload);
    },
    getDoe() {
      return call(["admin", "custom", "doe"], {
        method: "GET",
        auth: true,
      });
    },
  },
  dashboard: {
    list(body) {
      return call(["admin", "dashboard"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    list1(params) {
      return call(["admin", "dashboard"], {
        method: "GET",
        params,
        auth: true,
      });
    },
    listLoggedUser(dateset) {
      return call(["admin", "dashboard", "teacher", "users-login-details"], {
        method: "GET",
        auth: true,
        params: dateset ? dateset : {},
      });
    },
    lessons(dateset) {
      return call(["admin", "dashboard", "teacher", "lessons-completion"], {
        method: "POST",
        auth: true,
        body: dateset ? dateset : undefined,
      });
    },

    classroom(payload) {
      return call(["admin", "classroom", "info"], {
        method: "POST",
        body: {
          classrooms: payload,
        },
      });
    },
    topHashtags() {
      return call(["admin", "dashboard", "parent", "top-hashtags"], {
        method: "GET",
        auth: true,
      });
    },
    topWebPlatforms() {
      return call(["admin", "dashboard", "parent", "top-webplatforms"], {
        method: "GET",
        auth: true,
      });
    },
  },
  DashboardParent: {
    fetchclubprogress(payload) {
      return call(["admin", "dashboard", "parent", "clubs-progress"], {
        method: "POST",
        body: payload,
      });
    },

    fetchclassprogress(payload) {
      return call(["admin", "dashboard", "parent", "classrooms-progress"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms ? payload.classrooms : [],
          student: payload?.students || "",
          lessons: payload.lessons,
          weeks: payload.weeks,
        },
      });
    },
    topHashtags(payload) {
      if (!payload?.hashtag?.length) delete payload.hashtag;
      return call(["admin", "dashboard", "parent", "top-hashtags"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms,
          student: payload?.students || "",
          lessons: payload.lessons,
          limit: payload?.limit,
          days: payload?.days,
          hashtags: payload?.hashtag,
        },
      });
    },
    myTopHashtags(payload) {
      if (!payload?.hashtag?.length) delete payload.hashtag;
      return call(["admin", "dashboard", "parent", "my-top-hashtags"], {
        method: "POST",
        body: {
          student: payload?.students || "",
          limit: payload?.limit,
          days: payload?.days,
          hashtags: payload?.hashtag,
        },
      });
    },

    lessonhashtagData(payload) {
      if (!payload?.hashtag?.length) delete payload.hashtag;
      return call(["admin", "dashboard", "parent", "top-lesson-hashtags"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms,
          student: payload?.students || "",
          lessons: payload.lessons,
          limit: payload?.limit,
          days: payload?.days,
          hashtags: payload?.hashtag,
        },
      });
    },
    topWebPlatforms(payload) {
      return call(["admin", "dashboard", "parent", "top-webplatforms"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms,
          student: payload?.students || "",
          lessons: payload.lessons,
          limit: payload?.limit,
          days: payload?.days,
        },
      });
    },

    collaborations(payload) {
      return call(["admin", "dashboard", "parent", "collaborations"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms ? payload.classrooms : [],
          student: payload?.students || "",
          lessons: payload.lessons,
          days: payload?.days,
        },
      });
    },

    McqProgress(payload) {
      return call(["admin", "dashboard", "parent", "mcq-progress"], {
        method: "POST",
        body: {
          startDate: payload.from,
          endDate: payload.to,
          classrooms: payload.classrooms ? payload.classrooms : [],
          student: payload?.students || "",
          lessons: payload.lessons,
          weeks: payload?.weeks?.length ? payload?.weeks : null,
        },
      });
    },
  },
  admin: {
    list(params) {
      return call(["admin", "user"], {
        method: "GET",
        auth: true,
        params: formatObject(params),
      });
    },
    update(payload, userId) {
      return makeRequest("PATCH", `${API_URL}/admin/user/${userId}`, payload);
    },
    details(id) {
      return call(["admin", "user", id], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/user`, payload);
    },
    delete(userId) {
      return call(["admin", "user", userId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  users: {
    // list(payload) {
    //   return call(["user"], {
    //     method: "GET",
    //     auth: true,
    //     ...(payload
    //       ? {
    //           params: payload,
    //         }
    //       : {}),
    //   });
    // },
    list(payload = {}) {
      return call(["user"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    classroomList(payload) {
      return call(["user"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/user`, payload);
    },
    bulk_update(payload) {
      return call(["user", "bulk-edit"], {
        method: "PUT",
        body: payload,
        auth: true,
      });
    },
    update(payload, userId) {
      return makeRequest("PATCH", `${API_URL}/user/${userId}`, payload);
    },
    backgroundTransparent(payload,userId){
      return makeRequest("POST",`https://odin-parentapp.cubettech.in/api/file-upload/transperent`,payload);
    },
    delete(userId) {
      return call(["user", userId], {
        method: "DELETE",
        auth: true,
      });
    },
    details(id) {
      return call(["user", id], {
        method: "GET",
        auth: true,
      });
    },
    uploadAvatar(payload) {
      return makeRequest("POST", `${API_URL}/user/set/avatar`, payload);
    },
    // search(classroom_id) {
    //   return makeRequest("POST", `${API_URL}/user?classroom=${classroom_id}`);
    // },
    search(params) {
      return call(["user"], {
        method: "POST",
        auth: true,
        // params,
      });
    },
    notification(payload) {
      return call(["user", "notification"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    listNotification(params = {}) {
      return call(["user", "notification"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    notificationDetails(id) {
      return call(["user", "notification", id], {
        method: "GET",
        auth: true,
        params: {
          notificationId: id,
        },
      });
    },
    forgotPassword(payload) {
      return call(["auth", "forgotPassword"], {
        method: "POST",
        body: payload,
      });
    },
    resetPassword(payload) {
      return call(["auth", "resetPassword"], {
        method: "POST",
        body: payload,
      });
    },
    multiDelete(userId) {
      return call(["user", "bulk-delete"], {
        method: "DELETE",
        body: { userId },
        auth: true,
      });
    },
  },
  schools: {
    list() {
      return call(["admin", "school"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return call(["admin", "school"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    update(payload, schoolId) {
      return call(["admin", "school", schoolId], {
        method: "PATCH",
        body: payload,
        // auth: true,
      });
    },
    delete(schoolId) {
      return call(["admin", "school", schoolId], {
        method: "DELETE",
        auth: true,
      });
    },
    details(schoolId) {
      return call(["admin", "school", schoolId], {
        method: "GET",
        auth: true,
      });
    },
  },
  organizations: {
    list() {
      return call(["admin", "organization"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/organization/`, payload);
    },
    update(payload, organizationId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/organization/${organizationId}`,
        payload
      );
    },
    delete(organizationId) {
      return call(["admin", "organization", organizationId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  courses: {
    getFile(payload) {
      return call(["admin", "resource", "get-file"], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    list() {
      return call(["admin", "course"], {
        method: "GET",
        auth: true,
      });
    },
    getLesson({ id, type }) {
      return call(["admin", "resource", "get-resouce-url"], {
        method: "GET",
        params: { type, id },
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/course`, payload);
    },
    edit(payload, courseId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/course/${courseId}`,
        payload
      );
    },
    details(courseId) {
      return call(["admin", "course", courseId], {
        method: "GET",
        auth: true,
      });
    },
    addExisting(courseId, payload) {
      return call(["admin", "course", courseId, "resource"], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    deleteSingle(payload, courseId, resourceId) {
      return call(["admin", "course", courseId, "resource", resourceId], {
        method: "DELETE",
        body: payload,
        auth: true,
      });
    },
    delete(courseId) {
      return call(["admin", "course", courseId], {
        method: "DELETE",
        auth: true,
      });
    },
    dragLessons(payload, courseId) {
      return call(["admin", "course", "save-lesson-order", courseId], {
        method: "PATCH",
        auth: true,
        body: payload,
      });
    },
  },
  test: {
    list(payload) {
      return call(["admin", "mcq", "list-tests"], {
        method: "GET",
        params: payload || {},
        auth: true,
      });
    },
    report(payload) {
      return call(["admin", "mcq", "lesson-mcq-report"], {
        method: "GET",
        params: payload || {},
        auth: true,
      });
    },
    downloadReport(payload) {
      return call(["admin", "mcq", "generate-lesson-mcq-report"], {
        method: "GET",
        params: payload || {},
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/mcq/insert-tests/`, payload);
    },
    uploadAssertion(payload) {
      return makeRequest(
        "POST",
        `${API_URL}/admin/mcq/users-test-assign/`,
        payload
      );
    },
    update(payload) {
      return call(["admin", "mcq", "update-test"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    delete(testid) {
      return call(["admin", "mcq", "insert-tests"], {
        method: "DELETE",
        body: testid,
        auth: true,
      });
    },
    getReport(testid) {
      return call(["admin", "mcq", "usersBytest"], {
        method: "GET",
        params: { testid },
        auth: true,
      });
    },
    byFilter(subject, grade) {
      return call(["admin", "mcq", "getTestsForLesson"], {
        method: "GET",
        auth: true,
        // params: {
        //   subject,
        //   grade,
        // },
      });
    },
    publish(body) {
      return call(["admin", "mcq", "publish"], {
        method: "PATCH",
        body,
        auth: true,
      });
    },
  },
  role: {
    list() {
      return call(["admin", "role", "admins"], {
        method: "GET",
        auth: true,
      });
    },
    listMenu() {
      return call(["admin", "menu"], {
        method: "GET",
        auth: true,
      });
    },
    addRole(payload) {
      return call(["admin", "role"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    delete(id) {
      return call(["admin", "role", id], {
        method: "DELETE",
        auth: true,
      });
    },
    update(payload, id) {
      return call(["admin", "role", id], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
  },
  survey: {
    list() {
      return call(["admin", "mcq", "list-survay"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/mcq/survay/`, payload);
    },
    uploadAssertion(payload) {
      return makeRequest(
        "POST",
        `${API_URL}/admin/mcq/users-survey-assign/`,
        payload
      );
    },
    update(payload) {
      return call(["admin", "mcq", "update-survay"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    delete(surveyid) {
      return call(["admin", "mcq", "survay"], {
        method: "DELETE",
        body: surveyid,
        auth: true,
      });
    },
    getReport(surveyid) {
      return call(["admin", "mcq", "usersBysurvey"], {
        method: "GET",
        params: { surveyid },
        auth: true,
      });
    },
  },
  rewards: {
    list(params) {
      return call(["admin", "reward"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    create(payload) {
      return makeRequest("POST", `${API_URL}/admin/reward`, payload);
    },
  },

  groups: {
    list(payload) {
      return call(["admin", "chat", "group"], {
        method: "GET",
        auth: true,
        ...(payload
          ? {
              params: payload,
            }
          : {}),
      });
    },
    history(payload) {
      return call(["admin", "chat", "history"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    add(payload) {
      return call(["admin", "chat", "group"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    update(payload) {
      return call(["admin", "chat", "group"], {
        method: "PUT",
        body: payload,
        auth: true,
      });
    },
    delete(groupid) {
      return call(["admin", "chat", "group"], {
        method: "DELETE",
        body: groupid,
        auth: true,
      });
    },
    sendChatReply(payload) {
      return call(["admin", "chat", "sendMessage"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    sendMessage(id, payload) {
      return call(["admin", "chat", "group", "sendMessage", id], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    chatExport() {
      return call(["admin", "chat", "exportChat"], {
        method: "GET",
        auth: true,
      });
    },
  },

  subjects: {
    list() {
      return call(["admin", "subject"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/subject/`, payload);
    },
    update(payload, subjectId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/subject/${subjectId}`,
        payload
      );
    },
    delete(subjectId) {
      return call(["admin", "subject", subjectId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  grades: {
    list() {
      return call(["admin", "grade"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return call(["admin", "grade"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    update(payload, gradeId) {
      return call(["admin", "grade", gradeId], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
  },
  books: {
    exportBonus() {
      return call(["admin", "website", "bonus", "export"], {
        method: "GET",
        auth: true,
      });
    },
    list() {
      return call(["admin", "resource"], {
        method: "GET",
        auth: true,
      });
    },
    search(payload) {
      return call(["admin", "resource", "search"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/resource`, payload);
    },
    update(payload, resourceId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/resource/${resourceId}`,
        payload
      );
    },
    delete(resourceId) {
      return call(["admin", "resource", resourceId], {
        method: "DELETE",
        auth: true,
      });
    },
    details(resourceId) {
      return call(["admin", "resource", resourceId], {
        method: "GET",
        auth: true,
      });
    },
    version: {
      add(payload, bookId) {
        return makeRequest(
          "POST",
          `${API_URL}/admin/resource/version/${bookId}`,
          payload
        );
      },
      delete(versionId) {
        return call(["admin", "resource", "version", versionId], {
          method: "DELETE",
          auth: true,
        });
      },
    },
  },
  bonusContent: {
    list() {
      return call(["admin", "resource", "bonus"], {
        method: "GET",
        auth: true,
      });
    },

    search(payload) {
      return call(["admin", "resource", "bonus", "search"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/resource/bonus`, payload);
    },
    update(payload, resourceId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/resource/bonus/${resourceId}`,
        payload
      );
    },
    delete(resourceId) {
      return call(["admin", "resource", "bonus", resourceId], {
        method: "DELETE",
        auth: true,
      });
    },
    details(resourceId) {
      return call(["admin", "resource", "bonus", resourceId], {
        method: "GET",
        auth: true,
      });
    },
    version: {
      add(payload, bookId) {
        return makeRequest(
          "POST",
          `${API_URL}/admin/resource/bonus/version/${bookId}`,
          payload
        );
      },
      delete(versionId) {
        return call(["admin", "resource", "bonus", "version", versionId], {
          method: "DELETE",
          auth: true,
        });
      },
    },
  },
  apps: {
    list() {
      return call(["admin", "custom", "apps"], {
        method: "GET",
        auth: true,
      });
    },
    save(payload) {
      return makeRequest("POST", `${API_URL}/admin/custom/apps`, payload);
    },
    update(payload, appId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/custom/apps/${appId}`,
        payload
      );
    },
    delete(appId) {
      return call(["admin", "custom", "apps", appId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  otherApps: {
    list() {
      return call(["admin", "custom", "other-apps"], {
        method: "GET",
        auth: true,
      });
    },
    save(payload) {
      return makeRequest("POST", `${API_URL}/admin/custom/other-apps`, payload);
    },
    update(payload, appId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/custom/other-apps/${appId}`,
        payload
      );
    },
    delete(appId) {
      return call(["admin", "custom", "other-apps", appId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  websites: {
    list(params) {
      return call(["admin", "website"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/website/`, payload);
    },
    update(payload, websiteId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/website/${websiteId}`,
        payload
      );
    },
    delete(websiteId) {
      return call(["admin", "website", websiteId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  bonusWebsite: {
    list() {
      return call(["admin", "website", "bonus"], {
        method: "GET",
        auth: true,
      });
    },
    listSingle(id) {
      return call(["admin", "website", "bonus", id], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/website/bonus`, payload);
    },
    update(payload, websiteId) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/website/bonus/${websiteId}`,
        payload
      );
    },
    delete(websiteId) {
      return call(["admin", "website", "bonus", websiteId], {
        method: "DELETE",
        auth: true,
      });
    },
  },
  classroomAdmin: {
    list() {
      return call(["admin", "classroom"], {
        method: "GET",
        auth: true,
      });
    },
    classroomAdminTeachers(school_id) {
      return call(["admin", "user"], {
        method: "GET",
        auth: true,
        params: formatObject(
          school_id
            ? {
                school: school_id,
              }
            : {}
        ),
      });
    },
    classroomAdminUsers(school_id) {
      return call(["user"], {
        method: "GET",
        auth: true,
        params: school_id
          ? {
              school: school_id,
            }
          : {},
      });
    },
    listTeachers() {
      return call(["admin", "user"], {
        method: "GET",
        auth: true,
      });
    },
    add(payload) {
      return makeRequest("POST", `${API_URL}/admin/classroom`, payload);
    },
    update(payload, classroom_id) {
      return makeRequest(
        "PATCH",
        `${API_URL}/admin/classroom/${classroom_id}`,
        payload
      );
    },
    delete(classroom_id) {
      return call(["admin", "classroom", classroom_id], {
        method: "DELETE",
        auth: true,
      });
    },
  },

  lessonBuilder: {
    get(payload) {
      return call(["admin", "lessonbuilder"], {
        method: "GET",
        auth: true,
        params: formatObject(payload),
      });
    },
    getDetails(id) {
      return call(["admin", "lessonbuilder", id], {
        method: "GET",
        auth: true,
      });
    },
    save(payload) {
      return call(["admin", "lessonbuilder"], {
        method: "POST",
        auth: true,
        body: formatObject(payload),
      });
    },
    update(id, payload) {
      return call(["admin", "lessonbuilder", id], {
        method: "PATCH",
        auth: true,
        body: formatObject(payload),
      });
    },
    unpublish(id) {
      return call(["admin", "lessonbuilder", "unpublish", id], {
        method: "PATCH",
        auth: true,
      });
    },
    delete(id) {
      return call(["admin", "lessonbuilder", id], {
        method: "DELETE",
        auth: true,
      });
    },
    getLibrary(payload) {
      return call(["admin", "resource", "search"], {
        method: "POST",
        auth: true,
        params: payload,
      });
    },
    getMyLibrary(payload) {
      return call(["admin", "resource", "bonus"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    fileUpload(payload) {
      return makeRequest("POST", `${API_URL}/admin/file-upload`, payload);
    },
    imageUpload(payload){
      return makeRequest("POST", `${IMAGE_UPLOAD}/file-upload/transparent/multiple`, payload);
    }
  },
  myClassroom: {
    listSingleClassroom(classroom_id) {
      return makeRequest("GET", `${API_URL}/admin/classroom/${classroom_id}`);
    },
    listSingleClassroomChat(classroom_id) {
      return makeRequest(
        "GET",
        `${API_URL}/admin/classroom/${classroom_id}?type=chat`
      );
    },
    list(payload) {
      return call(["admin", "classroom"], {
        method: "GET",
        auth: true,
        ...(payload
          ? {
              params: payload,
            }
          : {}),
      });
    },
    myList() {
      return call(["admin", "classroom", "my-classrooms"], {
        method: "GET",
        auth: true,
      });
    },
    edit(classroom_id, payload) {
      return call(["admin", "classroom", classroom_id], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    details(classroom_id) {
      return call(["admin", "classroom", classroom_id], {
        method: "GET",
        auth: true,
      });
    },
    addExisting(classroom_id, payload) {
      return call(["admin", "classroom", classroom_id, "resource"], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    addUser(payload) {
      return call(["user"], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    sendMessage(classroom_id, payload) {
      return call(["admin", "classroom", "sendMessage", classroom_id], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    publishLesson(classroom_id, lesson_id) {
      return call(
        [
          "admin",
          "classroom",
          "publishLesson",
          classroom_id,
          "resource",
          lesson_id,
        ],
        {
          method: "POST",
          auth: true,
        }
      );
    },
    deleteSingle(payload, classroom_id, resourceId) {
      return call(
        ["admin", "classroom", classroom_id, "resource", resourceId],
        {
          method: "DELETE",
          body: payload,
          auth: true,
        }
      );
    },
    launchRating(payload) {
      return call(["admin", "ratings", "launch"], {
        method: "POST",
        auth: true,
        body: formatObject(payload),
      });
    },
    dragLessons(payload, classroom_id) {
      return call(["admin", "classroom", "save-lesson-order", classroom_id], {
        method: "PATCH",
        auth: true,
        body: payload,
      });
    },
  },
  device: {
    downloadSheet(params) {
      return call(["admin", "summery"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    list() {
      return call(["admin", "device"], {
        method: "GET",
        auth: true,
      });
    },
    space(payload) {
      return call(["admin", "device", "storage-cleaning"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    summary(payload) {
      return call(["admin", "summery", "get"], {
        method: "GET",
        auth: true,
        params: payload,
      });
    },
    add(payload) {
      return call(["admin", "device"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    update(payload, deviceId) {
      return call(["admin", "device", deviceId], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    delete(deviceId) {
      return call(["admin", "device", deviceId], {
        method: "DELETE",
        auth: true,
      });
    },
    assignUser(payload, deviceId) {
      return call(["admin", "device", "assign", deviceId], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    multiUnpin(deviceid) {
      return call(["admin", "device", "bulk-unpin"], {
        method: "POST",
        body: { deviceid },
        auth: true,
      });
    },
    summaryButton() {
      return call(["admin", "summery"], {
        method: "GET",
        auth: true,
      });
    },
    location(body) {
      return call(["admin", "summery", "location"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    sleepAwake(body) {
      return call(["admin", "summery", "sleepAwake"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    multiUnpin(deviceid) {
      return call(["admin", "device", "bulk-unpin"], {
        method: "POST",
        body: { deviceid },
        auth: true,
      });
    },
    summaryButton() {
      return call(["admin", "summery"], {
        method: "GET",
        auth: true,
      });
    },
  },
  health: {
    create(payload) {
      return call(["admin", "health"], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    list() {
      return call(["admin", "health"], {
        method: "GET",
        auth: true,
      });
    },
    listSearch(payload) {
      return call(["admin", "health"], {
        method: "GET",
        auth: true,
        params: formatObject(payload),
      });
    },
    Areas() {
      return call(["admin", "ticketArea", "v2", "technicalSupport"], {
        method: "GET",
        auth: true,
        params: { export: true },
      });
    },
    Types() {
      return call(["admin", "ticketType", "v2", "technicalSupport"], {
        method: "GET",
        auth: true,
        params: { export: true },
      });
    },
    getTypes() {
      return call(["admin", "settings", "ticketType"], {
        method: "GET",
        auth: true,
      });
    },
    getAreas() {
      return call(["admin", "settings", "ticketArea"], {
        method: "GET",
        auth: true,
      });
    },
    notification(payload) {
      return call(["admin", "health", "notification"], {
        method: "POST",
        auth: true,
        body: payload,
      });
    },
    updateCategory(payload) {
      return call(["admin", "health", payload.ticketId], {
        method: "PATCH",
        auth: true,
        body: {
          type: payload.type,
          area: payload.area,
        },
      });
    },
    toggle(payload) {
      return call(["admin", "health", payload.id], {
        method: "PATCH",
        auth: true,
        body: {
          isClosed: payload.isClosed,
          closedOrReopenedMsg: payload.closedOrReopenedMsg,
        },
      });
    },
    history(payload) {
      return call(["admin", "chat", "history"], {
        method: "POST",
        body: payload,
        auth: true,
      });
    },
    updateEscalation(id, payload) {
      return call(["admin", "health", "update-escalation", id], {
        method: "PATCH",
        body: payload,
        auth: true,
      });
    },
    getHealthDetails(id) {
      return call(["admin", "health", id], {
        method: "GET",
        auth: true,
      });
    },
  },
  psm: {
    list(params) {
      return call(["admin", "psm", "filter-pms"], {
        method: "GET",
        params,
        auth: true,
      });
    },
    export(body) {
      return call(["admin", "psm", "export-history"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    listPsm(payload) {
      return call(["admin", "psm", "list-psm"], {
        method: "GET",
        auth: true,
        ...(payload
          ? {
              params: payload,
            }
          : {}),
      });
    },
    save(payload) {
      return makeRequest("POST", `${API_URL}/admin/psm`, payload);
    },
  },
  // health: {
  // 	list() {
  // 		return call(["admin", "health"], {
  // 			method: "GET",
  // 			auth: true,
  // 		});
  // 	},
  // 	toggle(payload) {
  // 		return call(["admin", "health", payload.id], {
  // 			method: "PATCH",
  // 			auth: true,
  // 			body: {
  // 				isClosed: payload.isClosed,
  // 				closedOrReopenedMsg: payload.closedOrReopenedMsg,
  // 			},
  // 		});
  // 	},
  // },
  // psm: {
  // 	list(params) {
  // 		return call(["admin", "psm", "filter-pms"], {
  // 			method: "GET",
  // 			params,
  // 			auth: true,
  // 		});
  // 	},
  // 	export(body) {
  // 		return call(["admin", "psm", "psm-export"], {
  // 			method: "POST",
  // 			body,
  // 			auth: true,
  // 		});
  // 	},
  // 	listPsm(payload) {
  // 		return call(["admin", "psm", "list-psm"], {
  // 			method: "GET",
  // 			auth: true,
  // 			...(payload
  // 				? {
  // 						params: payload,
  // 				  }
  // 				: {}),
  // 		});
  // 	},
  // 	save(payload) {
  // 		return makeRequest("POST", `${API_URL}/admin/psm`, payload);
  // 	},
  // },
  zoom: {
    save(body) {
      return call(["admin", "zoom-meeting"], {
        method: "POST",
        body,
        auth: true,
      });
    },
  },
  bbm: {
    get(params) {
      return call(["admin", "bbb"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    getDetails(id) {
      return call(["admin", "bbb", id], {
        method: "GET",
        auth: true,
      });
    },
    save(body) {
      return call(["admin", "bbb"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    delete(id) {
      return call(["admin", "bbb", id], {
        method: "DELETE",
        auth: true,
      });
    },
    edit(payload, id) {
      return call(["admin", "bbb", id], {
        method: "PATCH",
        auth: true,
        body: payload,
      });
    },
    endCall(id) {
      return call(["admin", "bbb", "end", id], {
        method: "PATCH",
        auth: true,
      });
    },
    publish(id, body) {
      return call(["admin", "bbb", "record", "publish", id], {
        method: "PATCH",
        auth: true,
        body,
      });
    },
    deleteRecord(id) {
      return call(["admin", "bbb", "record", "delete", id], {
        method: "DELETE",
        auth: true,
      });
    },
    start(id) {
      return call(["admin", "bbb", "start", id], {
        method: "PATCH",
        auth: true,
      });
    },
    joinMeeting(id) {
      return call(["admin", "bbb", "join", id], {
        method: "GET",
        auth: true,
      });
    },
    getMeetingUrl({ id, name }) {
      console.log("calling//");
      return call(["bbb", "guest-join", `${id}?name=${name}`], {
        method: "GET",
        auth: true,
      });
    },
    syncRecordings(id) {
      return call(["admin", "bbb", "record", "download", id], {
        method: "GET",
        auth: true,
      });
    },
  },
  profile: {
    uploadAvatar(payload) {
      return makeRequest("POST", `${API_URL}/admin/user/set/avatar`, payload);
    },
    changePassword(body, userId) {
      return call(["admin", "user", "change-password", userId], {
        method: "POST",
        body,
        auth: true,
      });
    },
  },
  insight: {
    getInsights(params) {
      return call(["admin", "insight"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    deleteInsight(id) {
      return call(["admin", "insight", id], {
        method: "DELETE",
        auth: true,
      });
    },
    save(body) {
      return call(["admin", "insight"], {
        method: "POST",
        body,
        auth: true,
      });
    },
    update(body) {
      return call(["admin", "insight", `${body._id}`], {
        method: "PATCH",
        body,
        auth: true,
      });
    },
    insightParams(params) {
      return call(["admin", "classroom"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    insightHashTags(params) {
      return call(["admin", "hashtag"], {
        method: "GET",
        auth: true,
        params,
      });
    },
    insightMoods(params) {
      return call(["admin", "moods"], {
        method: "GET",
        auth: true,
        params,
      });
    },
  },
};
